import React,{useEffect,useRef} from 'react'
import Carousel from 'react-elastic-carousel'
import { useDispatch } from 'react-redux';
import SlideProduct from '../../components/Product/SlideProduct'
import useWindowDimensions from '../useWindowDimensions';
export default function Products({items}) {
  const {width}=useWindowDimensions()
  const carouselRef = useRef(null);
  const dispatch = useDispatch()
  let resetTimeout;
  return (

    <Carousel 
      ref={carouselRef}
      
      onNextEnd={({ index }) => {
          clearTimeout(resetTimeout)
          if (index  === items.length-5) {
              resetTimeout = setTimeout(() => {
                carouselRef.current.goTo(0)
            }, 1400) // same time
          }
      }}
      onPrevStart={({index})=>{
        clearTimeout(resetTimeout)
        if (index  === 0) { 
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(items.length-5)
          }, 1400) // same time
        }
      }}
      disableArrowsOnEnd={false}
      pagination={false}
      onChange={()=>{
        //console.log(carouselRef.current.state.activeIndex)
      }}
      itemsToShow={width<1140 ? (width>980 ? 3 : 2) : 5}>
      {
        items.map((item,index)=>{
          return(
            <SlideProduct key={item.id} item={item} />
          )
        })
      }
    </Carousel>
  )
}
