import styled from "styled-components";
import { rgba } from "polished";
const Header=styled.nav`
  *::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
  }
  display:flex;
  align-items:center;
  .balance-mobile{
    display:none!important;
  }
  @media only screen and (max-width:1140px){
    .balance{
      display:none!important;
    }
    .loginDrop{
      width:100%!important;

    }
    .balance-mobile{
      display:block!important;
    }
  }
  .balance{
    position:relative;
        min-width:100px;

    span{
      position:absolute;
      top:5px;
      font-weight:700;
      font-size:13px;
      left:calc(22%);
    }
    margin-right:10px;
    
    border-radius:15px;
    padding:22px 20px 5px 15px;
    color:#fff;
    box-shadow:0px 0px 12px #ddd;
    cursor:no-select;
    user-select:none;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: #dedede;
  }
  .balance.plus{
    background-color:green;
  }
  .balance.times{
    background:#121229;
    
  }
  justify-content:space-between;
  height:100px;
  padding:0px 200px;
  background-color:${({theme})=>theme.header};
  .loginDrop{
    box-shadow:0px 0px 44px #000;
    position:absolute;
    top:106%;
    border-radius:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    background-color:${({theme})=>theme.altHeader};
    color:${({theme})=>theme.color};
    align-items:center;
    z-index:200;
    width:96%;
    a{
      &:last-child{
        border-bottom-left-radius:20px;
        border-bottom-right-radius:20px;
      }
      width:100%;
      background-color:${({theme})=>theme.altHeader};
      display:block;
      transition:300ms all;
      text-decoration:none;
      font-size:13px;
      color:${({theme})=>theme.color}!important;
      padding:10px 0px;import { rgba } from 'polished';
      text-align:center;
      &:hover{
        color:#000!important;
        background-color:#fff;
      }
    }
  }
  .logo{
    
    img{
      float:right;
      height:180px;
      &:hover{
        filter:drop-shadow(0px 0px 1px  ${({theme})=>theme.elementShadow});
      }
    }
  }
  
  .search-box{
    display:flex;
    align-items:center;
    border:1px solid ${({theme})=>theme.elementBackground};
    background-color:${({theme})=>theme.color};
    border-radius:10px;
    padding-left:20px;
    color:#000;
    &:focus-within,&:hover{
      -webkit-box-shadow: 0px 0px 4px 0px ${({theme})=>theme.elementShadow};
      -moz-box-shadow: 0px 0px 4px 0px ${({theme})=>theme.elementShadow};
      box-shadow: 0px 0px 4px 0px ${({theme})=>theme.elementShadow};
    }
    svg{
      border:0;
      outline:0;
    }
    input,button{
      margin-left:5px;
      border:0;
      padding:12px 0px;
      color:#000;
      outline:0;
    }
    input{
      width:300px;
      background-color:${({theme})=>theme.color};
      color:#000;
      padding-left:15px;
      @media only screen and (max-width:1140px){
        width:150px;
      }
    }
    button{
      border-top-right-radius:10px;
      border-bottom-right-radius:10px;
      width:80px;
      font-weight:900;
      margin:0px;
      background-color:#fafafa;
      transition:300ms all;
      &:hover{
        background-color:${({theme})=>theme.altHeader};
        color:#fff;
        box-shadow:0px 0px 4px 0px #616161;
      }
    }
  }
  .buttons{
    
    display:flex;
    align-items:center;
    justify-content:center;
    a{
      color:#ffff;
    }
    button{
      margin-right:10px;
      border:0;
      outline:0;
      background-color:${({theme})=>theme.elementBackground};
      border-radius:15px;
      color:#fff;
      box-shadow:0px 0px 2px #000;
      font-weight:700;
      height:50px;
      font-size:14px;
      display:flex;
      align-items:center;
      padding:10px 25px 10px 20px;
      svg{
        font-size:22px;

        margin:5px;
      }
      text-align:right;
      div{
        position:relative;
        span{
          position:absolute;
          
          bottom:-4px;
          right:-3px;
          font-size:10px;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #dedede;
          background-color:${({theme})=>theme.notifyCircleBackground};
          border:1px solid #fff;
          font-weight:bold;
          color:#fff;
          padding:1.3px 5.5px;
          border-radius:90px;
          display:flex;justify-content:center;align-items:center;
          z-index:10;
        }
        margin-right:10px;
      }

      &:focus-within,&:hover{
        -webkit-box-shadow: 0px 0px 3px 1px ${({theme})=>theme.elementShadow};
        -moz-box-shadow: 0px 0px 3px 1px ${({theme})=>theme.elementShadow};
        box-shadow: 0px 0px 3px 1px ${({theme})=>theme.elementShadow};
      }
    }
    
    svg{
      cursor:pointer;
      margin-right:20px;
      color:${({theme})=>theme.color};
      font-size:22px;
      &:hover{
        filter:drop-shadow(0px 0px 3px  ${({theme})=>theme.elementShadow});
      }
    }
   
  }
  @media only screen and (max-width:1140px){
    .box-none{
      display:none;
    }
    .buttons > a{
      display:none;
    }
    .buttons .closer {
      display:none;
    }
    .search-box{
      width:100%;
      input{
        width:100%;
      }
    }
    justify-content:space-between;
    padding:0px;
    .buttons{
      button{
        margin:0px;
      }
    }
      padding:0px 10px;
    .logo{
      img{
        height:40px;
      }
    }
  }
`

const BottomNav=styled.nav`
  width:100%;
  display:none;
  z-index:100;
  background-color:${({theme})=>theme.header};
  position:fixed;
  bottom:0;
  height:50px;
  align-items:center;
  justify-content:center;
  .pop-up{
    .close *{
      font-size:30px;
    }
    .close button{
      padding:20px 60px;
      position:absolute;
      top:0;
      right:0;
    }
    .category-list{
      overflow-y:scroll;
      max-height:80vh;
      margin-block-start:0;
      margin-block-end:0;
      margin-inline-start:0;
      margin-inline-end:0;
      padding-inline-start:0;
      justify-content:center;
      ul{
        width:70vw;
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-block-start:0;
        margin-block-end:0;
        margin-inline-start:0;
        margin-inline-end:0;
        padding-inline-start:0;
        justify-content:center;
      }
      li{
        width:100vw;
        display:flex;
        flex-direction:column;
        align-items:center;
        min-height:80px;
        justify-content:center;
        ul{
          display:none;
        }
      }
      li:nth-child(2n+1){
        background-color:${({theme})=>theme.dropDownBackground};
      }
      li:nth-child(2n+0){
        background-color:${({theme})=>theme.elementBackground};
      }
      button{
        border:0;
        font-size:24px;
        margin-top:20px;
        background-color:transparent;
        
      }
    }
    display:flex;
    align-items:center;
    justify-content:center;
    position:fixed;
    z-index:200;
    width:100%;
    height:calc(100% - 51px);
    transition:400ms all;
    top:0;
    left:0;
    background-color:${({theme})=>rgba(theme.background,0.9)};
  }
  a{
    height:50px;
    cursor:pointer;
    color:#fff;
    color:${({theme})=>theme.element};
    width:20%;
    display:flex;
    font-size:10px;
    text-decoration:none;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    div{
      position:relative;
    }
    div span{
      position:absolute;
      z-index:10;

      border-radius:100%;
      padding:1.2px 3.4px;
      background-color:${({theme})=>theme.notifyCircleBackground};
      color:${({theme})=>theme.notifyCircle};
      top:-25px;
      right:-5px;
    }
    &:focus-within,&:hover{
      -webkit-box-shadow: 0px 0px 3px 1px ${({theme})=>theme.elementShadow};
      -moz-box-shadow: 0px 0px 3px 1px ${({theme})=>theme.elementShadow};
      box-shadow: 0px 0px 3px 1px ${({theme})=>theme.elementShadow};
    }
    &:not(:last-child){
      border-right:1px solid ${({theme})=>theme.elementBackground};
    }
    &:hover{
      background-color:${({theme})=>theme.elementBackground};
    }
    svg{
      font-size:20px;
    }
  }
  @media only screen and (max-width:1140px){
    display:flex;
  }
`

const HeaderAlt=styled.div`
  background-color:${({theme})=>theme.altHeader};
  width:100%;
  color:${({theme})=>theme.color};
  position:relative;
  @media only screen and (max-width:1140px){
    display:none;
  }
  button{
    color:${({theme})=>theme.color};
    font-weight:800;
    font-size:14px;
    line-height:18px;
    font-family:'Overpass', sans-serif!important;
  }
  &>ul{
    .sub-category{
      margin-top:-4px;
      border-bottom-left-radius:80px;
      border-bottom-right-radius:80px;
      box-shadow:2px 2px 5px rgba(0,0,0,.3);

      button{
        width:25%;
        min-height:40px;
        max-height:40px;
        padding:5px;
        margin:8px;
        font-size:14px;
        color:${({theme})=>theme.color};
        font-family: 'Overpass', sans-serif!important;
        transition:500ms all;
        font-weight:bold;
        background-color:rgba(0,0,0,.41);
        border:.1px solid rgba(0,0,0,.058);
        text-shadow:1px 1px 3px #616161;
        &:hover{
          background-color:rgba(0,0,0,.9);
        }
      }
      justify-content:center;
      align-items.center;
      padding:30px 0px;
      flex-wrap:wrap;
    }
    padding-inline-start:0px;
    margin-block-start:0px;
    margin-block-end: 0px;
    list-style:none;
    display:flex;
    justify-content:center;
    align-items:center;
    height:60px;
    &>li{
      
      height:60px;
      color:#fff!important;      
      background-color:${({theme})=>theme.altHeader};
      display:flex;
      justify-content:center;
      align-items:center;
      font-size:12px;
      max-width:150px;
      text-align:center;
      cursor:pointer;
      div{
        position:absolute;
        top:calc(100% + 3px);
        background-color:#bbb;
        display:none;
        width:50%;
        left:25%;
        z-index:100;
        
      }
      &>button{
        padding:0px 25px;

      }
      button{
        border:0;outline:0;
        width:100%;
        background:transparent;
      }
      ul{
        display:none;
        position:absolute;
        top:calc(100% + 1px);
        flex-direction:column;
        min-width:5%;
        // max-height:15vh;
        // overflow-y:auto;
        background-color:${({theme})=>theme.altHeader};
        justify-content:center;
        align-items:center;       
        padding-inline-start:0px;
        margin-block-start:0px;
        z-index:100; 

        li{
          width:100%;
          
          text-shadow:0px 0px 10px ${({theme})=>theme.element};
          border-bottom:.2px solid ${({theme})=>theme.altHeaderBorder};
          &:hover{
            background-color:rgba(0,0,0,.1);
            text-shadow:0px 0px 1px ${({theme})=>theme.element};
          }
          padding:0px 25px;
          display:flex;
          justify-content:center;
          align-items:center;
          font-size:12px;
          max-width:150px;
          text-align:center;
          cursor:pointer;
          min-height:50px;
        }

      }
      &:hover{
        ul{
          display:flex;
        }
        div{
          display:flex;
        }
        text-shadow:0px 0px 1px ${({theme})=>theme.element};
      }
      &:hover{
        border-bottom:2px solid ${({theme})=>theme.color};
      }
      &:not(:last-child):before{
        content:'';
        display:block;
        top:25%;
        width:1px;
        height:30px;
        right:0px;
      }
    }

  }

`

const HeaderTop = styled.div`
  height:20px;
  background:#f2f2f2;
  display:flex;
  justify-content:space-between;
  & > ul{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    height:30px;
    padding:0px 10%;
 
    button {

      position:relative;
      height:30px;
      border:0;outline:0;
      svg{
        width:25px;
        height:15px;
        font-size:15px;
      }
      & > div{
        display:none;
        position:absolute;
        

        z-index:100;
        flex-direction:column;
        top:100%;
        left:0px;
        & > div{
          display:flex;
          justify-content:center;
          font-weight:bold;
          font-size:14px;
          align-items:center;
          min-width:70px;
          max-width:70px;  background-color:${({ theme }) => theme.altHeader};

          color:#fff;
          padding:10px;
          &:hover{
          background-color:${({ theme }) => theme.opacityColor};
          color:${({ theme }) => theme.background};
          }
        }

      }
      &:focus,&:hover{
        div{
          display:flex;
        }
      }
    }
    li{
      height:30px;
      display:flex;align-items:center;
      cursor:pointer;
      font-size:12px;
      margin-right:20px;
      a{
        color:${({ theme }) => theme.opacityColor};
        text-decoration:none;
        &:hover{
          color:${({ theme }) => theme.color}
        }
      }
    }
  }
  @media only screen and (max-width:1140px){
  }
`;

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.footerBackground};
  padding: 20px 20%;
  .copyright {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    div {
      margin-right: 20px;
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.opacityColor};
        &:hover {
          color: ${({ theme }) => theme.color};
        }
      }
    }
  }
  .about {
    display: flex;

    justify-content: space-between;
    p {
      font-size: 11px;
    }
    h1 {
      font-size: 16px;
      margin-bottom: 30px;
    }
    img {
      height: 0px;
    }
    div {
      padding-right: 10%;
      &:not(:first-child) {
        padding-right: 0px;
        padding-left: 13%;
        display: flex;
        align-items: center;
        width: 45%;
      }
    }
  }
  .footerNavs {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    & > div {
      display: flex;
      margin-right: 60px;
      flex-direction: column;
      h1.spaces {
        margin-bottom: 40px;
      }
      h1 {
        font-size: 18px;
        margin-bottom: 20px;
      }
      ul {
        padding-inline-start: 0px;
        margin-block-start: 0px;
        margin-block-end: 0px;
        display: flex;
        flex-direction: column;
        li {
          a {
            color: ${({ theme }) => theme.opacityColor};
            &:hover {
              color: ${({ theme }) => theme.color};
            }
            font-size: 12px;
            text-decoration: none;
          }
        }
      }
    }
  }
  * {
    color: #000 !important;
  }
  .follow {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.color};
    border-bottom: 1px solid ${({ theme }) => theme.color};

    div {
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.opacityColor};
        &:hover {
          color: ${({ theme }) => theme.color};
        }
      }
      padding: 20px 0px;
      display: flex;
      align-items: center;
      margin-top: -3px;
    }
    h1 {
      font-size: 18px;
    }
    svg {
      font-size: 28px;
      margin-left: 20px;
    }
    img {
      margin-left: 20px;
      margin-top: -3px;
      height: 40px;
    }
  }

  @media only screen and (max-width: 1140px) {
    padding: 20px 5%;
    .copyright {
      margin-bottom: 40px;
      font-size: 8px;
    }
    .follow {
      div h1 {
        font-size: 14px;
      }
      div svg {
        margin-top: -20px;
        font-size: 18px;
      }
      div img {
        margin-top: -10px;
        height: 25px;
      }
    }
    .about {
      padding: 0%;
      div {
        flex-wrap: wrap;
        &:not(:first-child) {
          width: 100%;
          padding-left: 0%;
          border: 0;
        }
        padding: 0;
        margin: 0;
      }
      flex-wrap: wrap;
      img {
        margin: auto;
        padding: 0;
        width: 60%;
        height: auto;
      }
    }
  }
`;

export {Header,BottomNav,HeaderAlt,HeaderTop,FooterContainer}