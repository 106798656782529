import React,{useEffect,useState} from 'react'
import {PrintContainer} from '../../styles/Print';
import { useOrderRequests } from '../../helpers/OrderRequests';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
export default function PrintOrders() {
  const {id} = useParams()

  const [order,setOrder]=useState([])
  const [total,setTotal]=useState(0)
  const {getOrder}=useOrderRequests()
  useEffect(() => {
    if(order.length<1){
      getOrder(id).then(async (response)=>{
        setOrder(response.data.data)
        let unit=""

        setTimeout(() => {
          window.print()
        },2000);
      })
    }
  }, [order])
  

  return (
    <PrintContainer>
    
    <h1>Sipariş Detayı</h1>

    {
      order!=[] && order.order!=undefined ?
      <>
      <div className='head'>

      <div className='leftHead'>
        <div>
          <span>Tarih</span>
          <span>:</span>
          <span>{order.order.date}</span>
        </div>

        <div>
          <span>Sipariş No</span>
          <span>:</span>
          <span>{order.order.orderCode}</span>
        </div>

        <div>
          <span>Durum</span>
          <span>:</span>
          <span>{order.order.status}</span>
        </div>

      </div>

      <div className='rightHead'>
        <h4>{(JSON.parse(localStorage.getItem('customer'))).title}</h4>
        <img onContextMenu={()=>false} src={order.order.image} height={70} />
      </div>
      
    </div>
      </>:
      <></>
    }

    <ul>
      
    {
      (order!=[] && order.items!=undefined ? order.items:[]).map((x)=>{
        return (
          <li>
            <div><img src={x.image} /></div>
            <div>{x.productCode}</div>
            <div>{x.barcode}</div>
            <div>{x.quantity} {x.unit}</div>
            <div>%{x.vatRate}</div>
            <div>{order.order.currencyUnit} {x.totalPrice}</div>
            <div>{order.order.currencyUnit} {x.totalPriceWithVat}</div>
          </li>
        )
      })
    }
      <li>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>Toplam :{(order.order!=undefined ? order.order.currencyUnit:'')}{(order.order!=undefined ? order.order.totalPrice:'')} </div>
      </li>

    </ul>
  


    </PrintContainer>
  )
}
