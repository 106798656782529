import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'

import CartItems from '../components/Cart/CartItems'

export default function Basket(props) {
  
  const {getDiscover} = useProductRequests()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover,setDiscover]=useState([])

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:Math.floor(Math.random()*15)+Math.floor(Math.random()*20),count:10,sort:"date",sortedBy:Math.floor(Math.random()*10)%2==0 ? "desc":"asc"}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])

  return (
    <>
      
      <Header/>

      <div>
        

        <CartItems cart={basket} />


        <SlideProductContainer>
          
          <h1>Keşfet</h1>

          {
            discover.length > 0 ?
            <SlideProducts items={discover} />
            :
            <div className="loader">
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer> 

      
      </div>

      <BottomNav/>

      <Footer/>

    </>
  )
}
