import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus,  faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Currency } from 'react-intl-number-format/dist/index.js';
import { Fab } from '@mui/material'
import { setBasket } from '../stores/Cart';
import { useDispatch } from 'react-redux';
import { useCartRequests } from '../../helpers/CartRequests';
import { toast } from 'react-toastify';
export default function Item({item,cart}) {
  const dispatch=useDispatch()

  const [count,setCount]=useState(item.quantity)

  const {decrementCart,incrementCart,extractionCart}=useCartRequests()

  const increment=async(item,count=1)=>{
    const basket=await cart.map((sub)=>{
      if(sub.id===item.id){
        return {...sub,quantity:sub.quantity+count,total:sub.total+(sub.price*count)}
      }
      return sub
    })

    incrementCart(item.productId, count)
      .then((response) => {
        dispatch(setBasket(basket));
        setCount(item.quantity + count);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.message);
      });

  }


  const decrement=async(item,remove=false,count=1)=>{
    if(remove){
      const basket=await cart.filter((sub)=>{
        return sub.id!==item.id
      })
      extractionCart(item.productId).then(response=>{
        dispatch(setBasket(basket))
      })
      .catch(error=>{
        toast.error(error.response.data.message)
        console.log(error.response)
      })
    }else{
      
      const basket=await cart.map((sub)=>{
        if(sub.id===item.id){
          return {...sub,quantity:sub.quantity-count,total:sub.total-(sub.price*count)}
        }
        return sub
      })
      decrementCart(item.id,count).then(response=>{
        dispatch(setBasket(basket))
        setCount(item.quantity-count)
      })
      .catch(error=>{
        toast.error(error.response.data.message)
        console.log(error.response)
      })
    }
    
  }

  const handleChange=async(e)=>{
    if(e.target.value<item.quantity){
      decrement(item,e.target.value==0 ? true : false,item.quantity-e.target.value)
    }else{
      increment(item,e.target.value-item.quantity)
    }
  }


  return (
    <li key={'cart-item-'+item.id}>
      <div><img onContextMenu={()=>false} src={item.image==null ? 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png' : item.image} alt={item.title} /> </div>
      <div className='removeItem' onClick={()=>decrement(item,true)}><FontAwesomeIcon icon={faTrash} /></div>
      <div className='item-name'>
        <h1>{item.title}</h1>  
        <h2><Currency>{item.price}</Currency></h2>
      </div>
      <div>
        <Fab onClick={()=>increment(item)} className='buttons'><FontAwesomeIcon icon={faPlus}/> </Fab>
        <input type="text" onChange={handleChange} value={count} />
        <Fab onClick={()=>decrement(item,item.quantity==1 ? true : false)} className={`buttons ${item.quantity==1 ? 'delete' : ''}`}><FontAwesomeIcon icon={item.quantity==1 ? faTrashAlt : faMinus}/> </Fab>
      </div>
    </li>
  )
}
