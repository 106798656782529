import axios from 'axios'

export const useAuthRequests = () => {

  const loginRequest = async (email,password) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_LOGIN}`,
      data: {
        username: email,
        password: password
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_LOGIN}`
      }
    })
  }
  const getCustomer=async (token)=>{
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_CUSTOMER}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_REGISTER}`,
        'Authorization': `${token}`
      }
    })
  }
  const registerRequest = async (name,surname,email,password) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_REGISTER}`,
      data: {
        name: name,
        surname: surname,
        email: email,
        password: password
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_REGISTER}`
      }
    })
  }
  
  return {loginRequest,registerRequest,getCustomer}
}