import React,{useState} from 'react'
import {SlideProductItem} from '../../styles/Slide' 
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { useSelector,useDispatch } from 'react-redux';

import { setBasket } from '../stores/Cart';

import { useCartRequests } from '../../helpers/CartRequests';

import { useHistory } from 'react-router-dom';
import { Currency } from 'react-intl-number-format/dist/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';

import { faPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export default function SlideProduct({item}) {

  const {addCart,getCart} = useCartRequests()

  const history=  useHistory()

  const {login} = useSelector(state => state.auth)
  
  const {basket} = useSelector(state => state.cart)

  const dispatch=useDispatch()

  const getBasket=()=>{
    getCart().then(res=>{
      dispatch(setBasket(res.data.cart))
    })
  }
  const addToBasket=async()=>{

    if(login==true){
      const loader=toast.loading('Ürün Sepete Ekleniyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
      addCart([{id:item.id,count:1}]).then(res=>{
        getBasket()
        toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      })
      .catch(err=>{
        toast.update(loader, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      })
    
    }else{
      history.push('/giris-yap')
    }
  }

  return (
    <SlideProductItem key={item.id} slider={true}>
        <div className={"in-stock "+item.stockText}> {item.stockText.toUpperCase()} </div>
        <div className='image-box'>
          <img onContextMenu={()=>false} onClick={()=>history.push('/urun/'+item.slug)} src={item.image==null ? 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png' : item.image} alt={item.title} /> 
        </div>
        <h3> {item.title} </h3>
        {basket!=null && (basket.find(sub=>sub.id==item.id))!=undefined ? 
        <div className='product-count'>
        <Badge badgeContent={(basket.find(sub=>sub.id==item.id)).quantity} color="primary">
        <FontAwesomeIcon icon={faShoppingCart}/>
        </Badge>
        </div>
        : <></>}
      <div className="action">
        
        <div className="price">
          {/* <h5> <Currency>0</Currency> </h5> */}
          <div> <Currency>{item.salePrice}</Currency>  </div>
        </div>
        <div className="basket">
          <button onClick={()=>addToBasket()}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </SlideProductItem>
  )
}
