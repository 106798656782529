import styled from "styled-components";

const AuthPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 20vh;
  .logo{
    position: absolute;
    top:35px;
    display: flex;
    cursor: pointer;
    width:100%;
    height:auto;
    justify-content: center;
    align-items: center;
    &:hover{
        filter:drop-shadow(0px 0px 1px  ${({theme})=>theme.elementShadow});
    }
  }
  .form-navigator{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    width:100%;
    align-items: center;
    padding:0;
    &::before{
      display:none;
    }
  }
`

const Login=styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  padding-bottom: 20vh;
  .error-area{
    background-color: ${({theme})=>theme.error};
    color: ${({theme})=>theme.errorText};
    padding: 10px 0px;
  }
  .success-area{
    background-color: ${({theme})=>theme.success};
    color: ${({theme})=>theme.successText};
    padding: 10px 0px;
  }
  form{
    label{
      cursor:pointer;
    }
    margin-top:120px;
    div{
        border:0px;
        text-align:center;
        cursor:pointer;
        margin-top:10px;
        width:100%;
        &:before{
          content:'';
          border-bottom:0px!important;
        }
      
    }
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    input{
      background-color:${({theme})=>theme.header};
      border-radius:5px;
      border:0px!important;
      outline:0px!important;
      
    }
    
    .authBtn{
      width:96%;
      padding:10px 0px;
      margin-top:10px;
    }
    a{
      color:${({theme})=>theme.color};
      text-align:center;
      margin-top:20px;
      &:hover{
        color:${({theme})=>theme.opacityColor};
      }
      text-decoration:none;
    }
    width:35%;
    @media only screen and (max-width:1140px){
      width:80%;
    }

  }
`

export {Login,AuthPage}