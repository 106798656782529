import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios'

const getMainRoutes = async() => {
    // return [{"label":"Home","exact":true,"path":"/","auth":true},{"label":"Authentication/Logout","exact":true,"path":"/cikis-yap","auth":true},{"label":"Authentication/Login","exact":true,"path":"/giris-yap","auth":false},{"label":"Authentication/Register","exact":true,"path":"/kayit-ol","auth":"disable"},{"label":"Cart","exact":true,"path":"/sepetim","auth":true},{"label":"Discover","exact":true,"path":"/kesfet","auth":true},{"label":"NewProducts","exact":true,"path":"/yeni-urunler","auth":true},{"label":"Product","exact":true,"path":"/urun/:slug","auth":true},{"label":"Category","exact":true,"path":"/kategori/:slug","auth":true}]
    return await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API+process.env.REACT_APP_GET_ROUTES}`,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Target-URL': `${process.env.REACT_APP_API+process.env.REACT_APP_GET_ROUTES}`,
        }
    }).then(res => {
        localStorage.setItem('routes', JSON.stringify(res.data))
        return res.data
    })

}

export const auth = createSlice({
    name: 'auth',
    initialState: {
        routes: localStorage.getItem('routes') != null && localStorage.getItem('routes') != undefined ? JSON.parse(localStorage.getItem('routes')) : getMainRoutes(),
        user: [],
        login: localStorage.getItem('login') == 'true' ? true : false,
        token: localStorage.getItem('token') != null && localStorage.getItem('token') != '' && localStorage.getItem('token') != undefined ? localStorage.getItem('token') : null,
    },
    reducers: {
        loginChange: (state, action) => {
            state.login = true
            localStorage.setItem('login', action.payload.login)

            // state.user = action.payload.user
            // if (action.payload.user.status == 'Yönetici' || action.payload.user.status == 'Satıcı') {
            //     state.admin = true
            //     localStorage.setItem('admin', true)
            // }
            // localStorage.setItem('user', JSON.stringify(action.payload.user))

            state.token = action.payload.token
            localStorage.setItem('token', action.payload.token)
        



        },
        setCustomer: (state, action) => {
            state.user=action.payload
            localStorage.setItem('customer', JSON.stringify(action.payload))
            localStorage.setItem('title', action.payload.title)
            localStorage.setItem('id', action.payload.customerId)
            localStorage.setItem('currency',action.payload.currencyUnit)


        },
        logout: (state) => {
            state.login = false
            state.token = null
            state.admin = false
            state.user = []
            localStorage.removeItem('login')
            localStorage.removeItem('token')
            localStorage.removeItem('admin')
            localStorage.removeItem('user')


        },

    }
})

export const { loginChange, logout,setCustomer } = auth.actions
export default auth.reducer