import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
export default function BrandItems({brands,selectedBrand,setSelectedBrand}) {
  const [open,setOpen]=useState(false)
  const history=useHistory()
  return (
    <>
    {/* <h3 style={{cursor:'pointer'}} onClick={()=>setOpen(!open)}>Marka</h3> */}
    <ul className='brand-list' style={{display:true?'flex':'none'}}>
      {
        brands!=null && brands.length>0 ?
        brands.map(brand=>{
          return <>
          <li  key={brand.slug} className={(brand.slug==selectedBrand ? 'active active-brand' : '')} onClick={()=>setSelectedBrand(brand.slug)} >
            <div onClick={()=>setSelectedBrand(brand.slug)}><span style={{marginLeft:3}}>{brand.name}</span></div>
          </li>
          </>
        })
        :<></>
      }
    </ul>
    </>
  )
}
