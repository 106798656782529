import React from 'react'
import {NotFoundContainer} from '../styles/Content'
export default function NotFound() {
  return (
    <NotFoundContainer>
      <div class="fon">
        <div class="horizon"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <div class="hill"></div>

      <span class="not-found">Sayfa Bulunamadı</span>
      <span class="page-404">Hata 404</span>
      <div class="moon-sky"></div>
      <div class="satellite">☄</div>
        <div class="meteores">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
      <div class="cosmos-star">
        <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      </div>
      <div class="my-cat">
          <div class="ear-l">
              <div class="ear-fur-l"></div>
          </div>
          <div class="ear-r">
              <div class="ear-fur-r"></div>
          </div>
      <div class="head-cat">
        <div class="tabby-cat"></div>
        <div class="tabby-cat-1"></div>
        <div class="tabby-cat-2"></div>
          <div class="eye-l">
              <div class="eye-lz"></div>
          </div>
          <div class="cat-nose"></div>
          <div class="eye-r">
              <div class="eye-rz"></div>
          </div> 
          <div class="muzzle-cat"></div>
        <div class="	jaws-cat"></div>
        <div class="whiskers">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
        <div class="body-cat"></div>
        <div class="body-cat-1"></div>
        <div class="paw-cat-l"><div></div></div>
        <div class="paw-cat-r"><div></div></div>
        <div class="tail-cat"></div>
      </div>
      <div class="rock">
      <div class="rock-mountain_1"></div>
      <div class="rock-mountain_s_1"></div>
      <div class="rock-mountain_2"></div>
      <div class="rock-mountain_s_2"></div>
      <div class="rock-mountain_3"></div>
      <div class="rock-mountain_s_3"></div>
      </div>
      </div>
    </NotFoundContainer>
  )
}
