import React from 'react'
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useCartRequests } from '../../helpers/CartRequests';
import { toast } from 'react-toastify';
import { useSelector,useDispatch } from 'react-redux';
import {setBasket} from '../stores/Cart'
import { useHistory } from 'react-router-dom'
import { SlideProductItem } from '../../styles/Slide';

export default function Productproduct({product}) {

  const dispatch=useDispatch()

  const history=useHistory()

  const {addCart,getCart}=useCartRequests()
  
  const {basket}=useSelector(state=>state.cart)
  const {login}=useSelector(state=>state.auth)


  const getBasket=()=>{
    getCart().then(res=>{
      dispatch(setBasket(res.data.data))
    })
  }

  const addToBasket=async(product)=>{
    if(login==true ){
      
      const loader=toast.loading('Ürün Sepete Ekleniyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
      addCart([{id:product.id,count:1}]).then(res=>{
        getBasket()
        toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      })
      .catch(err=>{
        if(err.response!=undefined && err.response.data!=undefined){
          toast.update(loader, { render: err.res.data.message, type: "error", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        }
      })
    
    }else{
      history.push('/giris-yap')
    }
  }
  return (
    <SlideProductItem>
      <div className='image-box'>
          <img onContextMenu={()=>false} onClick={()=>history.push('/urun/'+product.slug)} src={product.image==null ? 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png' : product.image} alt={product.title} /> 
        </div>
        <h3> {product.title} </h3>
        <div className={"in-stock "+product.stockText}> {product.stockText.toUpperCase()} </div>
        {basket!=null && (basket.find(sub=>sub.id==product.id))!=undefined ? 
        <div className='product-count'>
        <Badge badgeContent={(basket.find(sub=>sub.id==product.id)).quantity} color="primary">
        <FontAwesomeIcon icon={faShoppingCart}/>
        </Badge>
        </div>
        : <></>}
      <div className="action">
        
        <div className="price">
          {/* <h5> <Currency>0</Currency> </h5> */}
          <div> <Currency>{product.salePrice}</Currency>  </div>
        </div>
        <div className="basket" >
          <button style={{cursor:product.inStock ? 'pointer':'not-allowed',opacity:product.inStock ? '100%':'10%'}} disabled={!product.inStock} onClick={()=>addToBasket(product)}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </SlideProductItem>

    
  
  )
}
