import styled from "styled-components";

const SlideProductItem = styled.div`
  margin: 10px;
  border-radius: 10px;
  color: #000;
  min-height: 340px;
  min-width: 240px;
  overflow: hidden;

  cursor: pointer;
  box-shadow: 0px 2px 4px #cccccc;

  &:hover {
    box-shadow: 0px 5px 10px #cccccc;
  }
  h3 {
    font-size: 15px;
    font-weight: normal;
    color: #000;
    padding: 5px 15px 10px 15px;
  }
  .image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    margin-bottom: 20px;
    overflow: hidden;
    object-fit: cover !important;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      user-select: none;
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  max-width: 250px;
  @media only screen and (max-width: 840px) {
    max-width: ${(props) =>
      props.slider != undefined && props.slider != null ? "97%" : "43%"};
    min-width: ${(props) =>
      props.slider != undefined && props.slider != null ? "97%" : "43%"};
    min-height: 420px;
    .image-box {
      height: 220px;
      img {
        height: 220px;
      }
    }
  }

  .product-count {
    color: #000;
    opacity: 0;
    top: 10px;
    right: 10px;
    transition: 600ms all;
    font-size: 22px;
    z-index: 5;
    background-color: ${({ theme }) => theme.mainThemeBackground};
    border-radius: 50%;
    padding: 5px;
  }
  position: relative;

  .action {
    position: absolute;
    width: 94%;
    right: 0px;
    bottom: 17px;
    display: flex;
    flex-direciton: row;
    justfiy-content: space-between;
    align-items: center;
    .price {
      display: flex;
      justfiy-content: center;
      align-items: center;
      font-weight: 700;
      width: 70%;

      transition: 600ms all;
      div {
        font-family: "Overpass", sans-serif !important;
        line-height: 22px;
        font-size: 25px;
      }
    }
    .basket {
      transition: 800ms all;
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        border: 0;
        color: #000;
        outline: 0;
        padding: 5px 10px;
        background-color: ${({ theme }) => theme.mainThemeBackground};
        transition: 600ms all;
        color: ${({ theme }) => theme.mainThemeColor};
        border-radius: 1000px;
        &:hover {
          color: ${({ theme }) => theme.mainThemeBackground};
          background-color: ${({ theme }) => theme.mainThemeColor};
        }
      }
    }
    .basket,
    .price {
      color: #000;
    }
  }
  &:hover {
    .product-count {
      opacity: 100%;
    }
    .action {
      .basket {
        opacity: 100%;
      }
      .price {
      }
    }
  }

  .in-stock {
    position: absolute;
    top: 0;
    right: 0;
    background: ${({ theme }) => theme.mainThemeBackground};
    padding: 2px 5px;
    font-family: "OverPass", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    box-shadow: 0px 0px 2px 0px #000;
  }
`;
const SlideBrandItem = styled.div`
  border: 1px solid ${({ theme }) => theme.elementBackground};
  margin: 10px;
  display: inline-block;
  border-radius: 30px;
  overflow: hidden;
  min-height: 110px;
  cursor: pointer;

  transition: 300ms all;
  &:hover {
    box-shadow: 0px 0px 7px #616161;
  }
  h3 {
    font-size: 14px;
    text-align: center;
    background: ${({ theme }) => theme.elementBackground};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;

    padding: 5px 10px;
  }
  img {
    width: 100%;
    padding: 5px;
    min-width: 190px;
    max-width: 190px;

    height: 190px;
    @media only screen and (max-width: 1140px) {
      min-width: 135px;
      max-width: 135px;
      height: 135px;
    }
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  @media only screen and (max-width: 1140px) {
    border-radius: 10px;
    width: 14%;
    h3 {
      font-size: 9px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    img {
      min-width: 100%;
      max-width: 100%;
      height: 110px;
    }
    height: 150px;
    min-height: 150px;
    max-height: 150px;
  }
  @media only screen and (max-width: 870px) {
    border-radius: 10px;
    width: 19%;
    h3 {
      font-size: 9px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    img {
      min-width: 100%;
      max-width: 100%;
      height: 70px;
    }
    height: 105px;
    min-height: 105px;
    max-height: 105px;
  }
  position: relative;
  .product-count {
    position: absolute;
    opacity: 0;
    top: 10px;
    right: 10px;
    transition: 600ms all;
    font-size: 22px;
    z-index: 5;
    background-color: ${({ theme }) => theme.mainThemeBackground};
    border-radius: 50%;
    padding: 5px;
  }
  .action {
    position: relative;
    .price {
      display: flex;
      opacity: 100%;
      flex-direction: column;
      justify-content: center;
      transition: 600ms all;
      align-items: center;

      h4 {
        font-size: 15px;
        color: ${({ theme }) => theme.mainThemeColor};
      }
      h5 {
        font-size: 13px;
        text-decoration: line-through;
      }
    }
    .basket {
      transition: 800ms all;
      top: 10px;
      opacity: 0;
      button {
        border: 0;
        outline: 0;
        width: 200px;
        padding: 10px 0px;
        background-color: ${({ theme }) => theme.mainThemeBackground};
        transition: 600ms all;
        color: ${({ theme }) => theme.mainThemeColor};
        border-radius: 10px;
        &:hover {
          background-color: ${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .basket,
    .price {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &:hover {
    .product-count {
      opacity: 100%;
    }
    .action {
      .basket {
        opacity: 100%;
      }
      .price {
        opacity: 0;
      }
    }
  }
`;
export {SlideProductItem,SlideBrandItem}