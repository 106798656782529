import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { InvoiceContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Currency } from 'react-intl-number-format/dist/index.js';
import { Button } from 'react-bootstrap'
import { Sentry } from 'react-activity'
export default function Basket(props) {
  
  const {id} = useParams()


  const {getDiscover} = useProductRequests()
  const {getOrder} = useOrderRequests()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover,setDiscover]=useState([])
  const [order,setOrder]=useState([])
  const [loading,setLoading]=useState(true)

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:Math.floor(Math.random()*15)+Math.floor(Math.random()*20),count:10,sort:"date",sortedBy:Math.floor(Math.random()*10)%2==0 ? "desc":"asc"}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])
  const [printOn,setPrintOn]=React.useState(false)
  const printPdf=()=>{
    setPrintOn(true)
    setTimeout(() => {
      setPrintOn(false)
    }, 5500);
  }
  useLayoutEffect(() => {
    
    if(order.length===0){

      getOrder(id).then((response)=>{
        setOrder(response.data.data)
        setLoading(false)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])
  const columns = [
    {
        name: '',
        selector: (row,index) => index+1,
    },
    {
        name: '',
        selector: (row,index) => <img onContextMenu={()=>false} height={40} src={row.image} />,
    },
    {
        name: 'Ad',
        selector: row => row.title,

    },
    {
        name: 'Barkod',
        selector: row => row.barcode,
    },
    {
        name: 'Stok Kodu',
        selector: row => row.productCode,
    },
    {
        name: 'Miktar',
        selector: row => row.quantity,
    },
    {
        name: 'Birim',
        selector: row => row.unit,
    },
    {
        name: 'Kdv',
        selector: row => "%"+row.vatRate,

    },
    {
        name: 'Fiyat',
        selector: row => <>{row.price}{order.order.currencyUnit}</>,

    },
    {
        name: 'Toplam',
        selector: row => <>{row.totalPrice}{order.order.currencyUnit}</>,

    },
    {
        name: 'Kdv Dahil',
        selector: row => <>{row.totalPriceWithVat}{order.order.currencyUnit}</>,

    },
  ];
  return (
    <>
      {printOn ? (
        <>
          <iframe
            src={"/yazdir/siparisler/" + id}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          <InvoiceContainer>
            <div
              style={{
                width: "100%",
                minHeight: 50,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  color: "#000",
                  border: "none",
                  outline: "1px solid lightblue",
                  marginRight: 20,
                  height: 30,
                  width: 70,
                }}
                onClick={() => {
                  if (!printOn) {
                    printPdf();
                  }
                }}
              >
                {printOn ? <Sentry /> : "PDF"}
              </button>
            </div>

            {order == null || (order.length === 0 && loading) ? (
              <>
                <div className="loader">
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
                <div className="loader" style={{ marginTop: -100 }}>
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="head">
                  <div className="leftHead">
                    <div>
                      <span>Tarih</span>
                      <span>:</span>
                      <span>{order.order.date}</span>
                    </div>

                    <div>
                      <span>Sipariş No</span>
                      <span>:</span>
                      <span>{order.order.orderCode}</span>
                    </div>

                    <div>
                      <span>Durum</span>
                      <span>:</span>
                      <span>{order.order.status}</span>
                    </div>
                  </div>

                  <div className="rightHead">
                    <h4>
                      {JSON.parse(localStorage.getItem("customer")).title}
                    </h4>
                    <img
                      onContextMenu={() => false}
                      src={order.order.image}
                      height={70}
                    />
                  </div>
                </div>

                <DataTable columns={columns} data={order.items} />

                <div className="total">
                  <div style={{ color: "#000" }} className="space"></div>
                  <div>
                    <span style={{ color: "#000" }}>Toplam Tutar</span>
                    <span>:</span>
                    <span style={{ color: "#000" }}>
                      {order.order.totalPrice}
                      {order.order.currencyUnit}
                    </span>
                  </div>
                </div>

                {/* <Button>YAZDIR</Button> */}
              </>
            )}
          </InvoiceContainer>
        </SlideProductContainer>

        <SlideProductContainer>
          <h1>Keşfet</h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
