import React,{useState,useEffect} from 'react'
import {Login} from '../../styles/Auth'
import Box from '@mui/material/Box';

import { LoadingButton } from '@mui/lab';
import {  styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router';

import { useDispatch,useSelector } from 'react-redux'
import { loginChange,setCustomer } from '../stores/Auth';

import { useAuthRequests } from '../../helpers/AuthRequests';
import { Link } from 'react-router-dom';

export default function LoginForm() {
  
  const {loginRequest,getCustomer} = useAuthRequests()

  const dispatch = useDispatch()

  const {login} = useSelector(state => state.auth)



  const [email,setEmail] = useState('')
  
  const [password,setPassword] = useState('')

  const [checked,setChecked] = useState(false)

  const [loading,setLoading] = useState(false)

  const [error,setError] = useState('')
  const [success,setSuccess] = useState('')

  const history=useHistory()

  const RedditTextField = styled((props) => (<TextField InputProps={{ disableUnderline: true }} {...props} />))(({ theme }) => ({}));

  const loginIn=async(e)=>{
    e.preventDefault();
    const debug=true
    if(loading){
      return
    }else if(email=='' || email.length<5 && debug==false){
      setError('Email adresinizi giriniz.')
    }else if(password=='' || password.length<6 && debug==false){
      setError('Şifrenizi giriniz.')
    }else{
      setLoading(true)

      loginRequest(email,password)
      .then( async(response)=> {
        setEmail('')
        setPassword('')
        setChecked(false)
        setError('')
        setSuccess(response.data.message+' Ana sayfaya yönlendiriliyorsunuz.')
        console.log(response)
        dispatch(loginChange({
          token:response.data.data.authenticationKey,
          login:true,
        }))
        getCustomer(response.data.data.authenticationKey).then(res=>{
          dispatch(setCustomer(res.data.data))
        })
        setTimeout(() => {
          history.push('/')
        }, 1000);

      })
      .catch( (error)=> {
        console.log(error)
        console.log(error.response)
        setError(error.response.data.message)
        setLoading(false)
      })

      

    }

    
  }
 
  return (
    <Login>
      <form onSubmit={loginIn}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            autoComplete="on"
            label="E Posta"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
            type="email"
            style={{ marginTop: 11 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            autoComplete="on"
            label="Şifre"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autocomplete="on"
            variant="filled"
            type="password"
            style={{ marginTop: 11 }}
          />
        </Box>
        <label>
          <div style={{ color: "#000!important", background: "#341f21" }}>
            {/* <p style={{color:'#fff',fontWeight:'bold'}}>UYGULAMA ÇEREZLERİNİZİ ve KİŞİSEL BİLGİLERİNİZİ KULLANMAZ,İZLEMEZ,TAKİP ETMEZ, YALNIZCA OTURUM AÇMANIZA YARDIMCI OLMAKTADIR!</p> */}
          </div>
          {/* <Checkbox checked={checked} onChange={()=>setChecked(!checked)} {...label}  /> Beni hatırla */}
        </label>
        {error != "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            {" "}
            <div className="error-area">{error}</div>{" "}
          </Box>
        )}
        {success != "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            {" "}
            <div className="success-area">{success}</div>{" "}
          </Box>
        )}
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          type="submit"
          variant="contained"
          className="authBtn"
        >
          Giriş Yap
        </LoadingButton>
        <div className="form-navigator" style={{justifyContent:'center',alignItems:'center'}}>
        </div>
      </form>
    </Login>
  );
}
