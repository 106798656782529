import React,{useState,useEffect,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SliderSwitch,CarouselContainer,SlideProductContainer} from '../styles/Home'
import SliderContainer from '../components/Slider'
import SlideProducts from '../components/Slider/Products'
import SlideBrands from '../components/Slider/Brands'

import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import { useHistory } from 'react-router-dom'

import {Helmet} from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";

export default function Home(props) {
  
  const {getProducts,getDiscover,getBrands}=useProductRequests()
  
  const {slider}=useSelector(state=>state.site)
  const [sliders,setSliders]=useState('test1')
  const [images,setImages]=useState([])

  const history=useHistory()

  const [discover,setDiscover]=useState([])
  const [products,setProducts]=useState([])
  const [brands,setBrands]=useState([])

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:Math.floor(Math.random()*5)+Math.floor(Math.random()*5),count:10,sort:"date",sortedBy:Math.floor(Math.random()*10)%2==0 ? "desc":"asc"}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
      })

      getProducts({page:1,count:10,sort:"date",sortedBy:"desc"}).then((response)=>{
        setProducts(response.data.data.items)
      }).catch((error)=>{
        

      })

      getBrands().then((response)=>{
        setBrands(response.data.data)
      }).catch((error)=>{
          history.push("/giris-yap");
      })

    }
  }, [])

  useEffect(() => {
    //console.log(slider)
    switch (sliders) {
      
      case 'test1':
        setImages([
          "https://dehasoft.com.tr/images/dehabot_pelerin2.png",
          "https://dehasoft.com.tr/images/Group-113-p-1600.png",
          "https://dehasoft.com.tr/images/slider-4-p-1600.png",
        ]);
        // setImages([""])
        break;
      default:
        break;
    }

  }, [sliders])

  return (
    <>
      <Helmet>
        <title>Ana Sayfa</title>
      </Helmet>
      {/* <CookieConsent
        location="bottom"
        buttonText="Tümünü Kabul Et"
        cookieName="dehasoft"
        style={{ background: "#DB2C1D",minHeight:'10vh',alignItems:'center' }}
        buttonStyle={{ color: "#000", fontSize: "15px" }}
        expires={250}
      >
        Bu site deneyimlerinizi kişiselleştirmek amacıyla KVKK ve GDPR uyarınca çerez(cookie) kullanmaktadır. Bu konu hakkında detaylı bilgi almak için çerez politikası ve aydınlatma metni sayfalarını gözden geçirebilirsiniz. Sitemizi kullanarak, çerezleri kullanmamızı kabul edersiniz.{" "}
        <span style={{ fontSize: "10px" }}> &copy; Dehasoft 2023</span>
      </CookieConsent> */}
      <Header/>

      <div>
        
        <CarouselContainer colors={slider}>

            <SliderSwitch>
              {/* <button onClick={()=>setSliders('test1')} className={sliders=='test1' ? 'active' : ''}>Afişlerimiz</button> */}
              {/* <button onClick={()=>setSliders('test2')} className={sliders=='test2' ? 'active' : ''}>Hepsi</button>  */}
              {/* <button onClick={()=>setSliders('test3')} className={sliders=='test3' ? 'active' : ''}>Hepsi</button>  */}
            </SliderSwitch>

            <SliderContainer images={images} />
          
        </CarouselContainer>

        {/* <SlideProductContainer width={'90%'} left={'5%'} >
          <h1  onClick={()=>history.push('/markalar')}>Markalar</h1>
          {
            brands!=null && brands!=undefined && brands.length > 0 ?
            <SlideBrands items={brands} />
            :
            <div className="loader">
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={150} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer >  */}


        <SlideProductContainer width={'90%'} left={'5%'} >
          <h1  onClick={()=>history.push('/yeni-urunler')}>Yeni Ürünler</h1>
          {
            products!=null && products!=undefined && products.length > 0 ?
            <SlideProducts items={products} />
            :
            <div className="loader">
              <ContentLoader  speed={0.5} width={221} height={300} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader  speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer > 
          

        <SlideProductContainer width={'90%'} left={'5%'}>
          <h1 onClick={()=>history.push('/kesfet')}>Keşfet</h1>
          {
            discover!=null && discover!=undefined && discover.length > 0 ?
            <SlideProducts items={discover} />
            :
            <div className="loader">
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer> 

      
      </div>

      <BottomNav/>

      <Footer/>

    </>
  )
}
