import React,{useEffect,useState} from 'react'
import {PrintContainer} from '../../styles/Print';
import { useOrderRequests } from '../../helpers/OrderRequests';
export default function Printcollections() {

  const [collections,setCollections]=useState([])
  const [total,setTotal]=useState(0)
  const {getCollections}=useOrderRequests()
  useEffect(() => {
    if(collections.length<1){
      getCollections().then(async (response)=>{
        console.log(response.data.data)
        setCollections(response.data.data)
        let totals=0
        let unit=""
        const seter=await response.data.data.collections.map(x=>{
          unit=x.currencyUnit
          totals+=(x.currencyCredit)
        })
        setTotal(unit+totals)

        setTimeout(() => {
          if(response.data.data.collections.length>0){
            window.print();
          }
        }, 2000);
      })
    }
  }, [collections])
  

  return (
    <PrintContainer>
      <h1>Cari Hareket Listesi</h1>
      <ul>
        {(collections.collections!=undefined && collections.collections.length>0 ? collections.collections:[]).map((x) => {
          return (
            <li>
              <div>#{x.collectionId}</div>
              <div>{x.documentNumber}</div>
              <div>{x.collectionType}</div>
              <div>
                {x.currency != true
                  ? x.credit + "₺"
                  : x.currencyCredit + x.currencyUnit}
              </div>
              <div>
                {x.currency != true
                  ? x.debt + "₺"
                  : x.currencyDebt + x.currencyUnit}
              </div>
              <div>{x.date}</div>
              <div>{x.explanation}</div>
            </li>
          );
        })}
        <li>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>Toplam : {collections.totalCredit + "₺"}</div>
        </li>
        <li>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>Bakiye : {collections.totalBalance + "₺"}</div>
        </li>
      </ul>
    </PrintContainer>
  );
}
