import { faBell, faMoon, faSearch, faShoppingBasket, faSun, faTags, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {Header,HeaderTop} from '../../styles/Header'
import Flags from 'country-flag-icons/react/3x2'
import Switch from "react-switch";
import { useSelector,useDispatch } from 'react-redux'
import { changeTheme } from '../stores/Site'
import { useHistory,useLocation } from 'react-router'
import { Menu } from '@headlessui/react'
import Categories from './Categories'

import logo from '../../mainlogo.png'
import { Currency } from 'react-intl-number-format/dist/index.js';

export default function HeaderComponent() {

  const dispatch = useDispatch()

  const history=useHistory()
  const location=useLocation()

  const {theme}=useSelector(state=>state.site)

  const {login}=useSelector(state=>state.auth)

  const {admin}=useSelector(state=>state.auth)

  const {basket}=useSelector(state=>state.cart)
  const [search,setSearch]=useState("")
  return (
    <>
      <HeaderTop>
       
      </HeaderTop>
      <Header>
        <div className={"logo " + (location.pathname != "/" ? "box-none" : "")}>
          <Link to="/">
            <img onContextMenu={() => false} src={logo} alt="Logo-Resim" />
          </Link>
        </div>
        <div
          className={
            "search-box " + (location.pathname == "/" ? "box-none" : "")
          }
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            value={search}
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                history.push("/search/" + search);
              }
            }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="ürün, kategori veya marka ara"
          />
          <button onClick={() => history.push("/search/" + search)}>
            {" "}
            ARA{" "}
          </button>
        </div>
        <div
          className={"buttons " + (location.pathname != "/" ? "box-none" : "")}
        >
          {login == false ? (
            <>
              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  {" "}
                  <FontAwesomeIcon icon={faUser} /> Giriş Yap
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  <Menu.Item>
                    <Link to="/giris-yap">Giriş Yap</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/kayit-ol">Kayıt Ol</Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </>
          ) : (
            <>
              <div
                className={
                  "balance " +
                  (JSON.parse(localStorage.getItem("customer")).balance > 0
                    ? "plus"
                    : "times")
                }
              >
                <span>Bakiyeniz</span>
                <Currency>
                  {JSON.parse(localStorage.getItem("customer")).balance}
                </Currency>
                {/* {(JSON.parse(localStorage.getItem('customer'))).currencyUnit} */}
              </div>
              {/* <Link to="/"><FontAwesomeIcon icon={faTags} /></Link> */}
              {/* <Link to="/"><FontAwesomeIcon icon={faBell} /></Link> */}
              <button
                onClick={() => history.push("/sepetim")}
                className="closer"
              >
                <div>
                  <FontAwesomeIcon icon={faShoppingBasket} />
                  <span>
                    {basket != null && basket != undefined ? basket.length : ""}
                  </span>
                </div>
                Sepetim
              </button>

              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  {" "}
                  <FontAwesomeIcon icon={faUser} />{" "}
                  <span style={{ display: "flex", flexWrap: "nowrap" }}>
                    Hesap{" "}
                  </span>{" "}
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  <Menu.Item>
                    <Link to="#" className="balance-mobile">
                      Bakiyeniz <br />
                      {JSON.parse(localStorage.getItem("customer")).balance}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/hareketlerim">Hareketler</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/faturalar">Faturalar</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/siparisler">Siparişlerim</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/urun-raporu">Ürün Raporu</Link>
                  </Menu.Item>
                  {admin == "true" ? (
                    <Menu.Item>
                      <Link to="/yonetici">Yönetici</Link>
                    </Menu.Item>
                  ) : (
                    <></>
                  )}
                  <Menu.Item>
                    <Link to="/cikis-yap">Çıkış Yap</Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </>
          )}
        </div>
      </Header>

      <Categories />
    </>
  );
}
