import styled from "styled-components";

const SliderSwitch=styled.div`
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  height:80px;
  button.active{
    color:${({theme})=>theme.color};
    border-radius:30px;
    background-color:${({theme})=>theme.background};
    font-weight:bold;
    &:after{
      background-#341f21;
      content:'';
      position:absolute;
      top:80%;
      right:50%;
      width:15px;
      height:9px;
      background-color:${({theme})=>theme.background};
      transform:rotate(45deg);
    }
  }
  button{
    height:40px;
    width:150px;
    border:0;
    outline:0;
    color:#fff;
    font-weight:normal;
    position:relative;
    background-color:transparent;
    &:not(:last-child){
      margin-right:20px;
    }
  }
`
const CarouselContainer=styled.div`
  transition:600ms all;
  .rec-arrow{
    position:absolute;
    z-index:10;
    background:transparent!important;
    color:${({theme})=>theme.color}!important;
    border:0!important;
    outline:0!important;
    box-shadow:0px 0px 0px!important;
    border-radius:0px !important;
    height:53vh!important;
    width:20%!important;
    top:-8vh;
    @media only screen and (max-width:1140px){
      height:43vh!important;
      top:-8vh;
    }
    &:hover{
      background-color:rgba(0,0,0,.003)!important;
    }
  }
  .rec-arrow-left{
    left:0px!important;
  }
  .rec-arrow-right{
    right:00px!important;
  }
  .rec-dot{
    background:${({theme})=>theme.carouselBackground}!important;
    transition:all 250ms ease!impoortant;
    &:hover{
      box-shadow:0 0 1px 2px ${({theme})=>theme.color}!important;
    }
  }
  .rec-dot_active{
    background:${({theme})=>theme.background}!important;
    border:0!important;
    box-shadow:0 0 1px 3px ${({theme})=>theme.color}!important;
  }
  .rec-item-wrapper img{
    width:60%;
    height:40vh;
    cursor:pointer;
    user-select:none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    /*border:4px solid;
    border: double 1em transparent;
    border-image:linear-gradient(rgba(${(props)=>props.colors.front},${({theme})=>theme.rgbaOpacity}) 0%,
          rgba(${(props)=>props.colors.center},${({theme})=>theme.rgbaOpacity}) 26%,
          rgba(${(props)=>props.colors.centerlast},${({theme})=>theme.rgbaOpacity}) 74%,
          rgba(${(props)=>props.colors.end},${({theme})=>theme.rgbaOpacity}) 100%) 30;
    background-origin: border-box;
    background-clip: content-box, border-box;*/
    border-radius:30px;
    @media only screen and (max-width:1140px){      
      border-radius:30px;
      width:90%;
      height:30vh;
    }
  }
  .slider-page-lists img{
    user-select:none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .active-slider-page{
    border:2px solid ${({theme})=>theme.background};
  }
  .rec-carousel-wrapper{
    padding-bottom:2vh;
    position:relative;
  }
  background:linear-gradient(90deg, rgba(${(props)=>props.colors.front},0.6) 0%, rgba(${(props)=>props.colors.center},0.6) 26%, rgba(${(props)=>props.colors.centerlast},0.6) 74%,rgba(${(props)=>props.colors.end},0.6) 100%);

  @media only screen and (max-width:1140px){
    height:56vh;
    & > div{
    margin-bottom:5vh;
    }
  }
`

const SlideProductContainer=styled.div`
  margin-left:${(props)=>props.left};
  position:relative;
  background-color:${({theme})=>theme.productsContainerBackground};
  @media only screen and (max-width:1140px){
    width:95%;
    margin-left:2.5%;
  }
  h1{
    margin-left:30px;
    &:hover{
      cursor:pointer;
      
    }
  }
  .rdt_TableBody > div:nth-child(2n+1){
    background:#dedede;
  }
  .rdt_TableBody>div{
    background:#fff;

  }
  .rec-arrow{
    position:absolute;
    z-index:10;
    color:${({theme})=>theme.color}!important;
    border:0!important;
    outline:0!important;
    box-shadow:0px 0px 0px!important;
    border-radius:50% !important;
    background-color:rgba(0,0,0,.08)!important;
    &:hover{
      background-color:rgba(0,0,0,.15)!important;
    }
  }
  .rec-arrow-left{
    border:1px solid #000;
    left:-5px;
  }
  .rec-arrow-right{
    border:1px solid #000;
    right:-5px;
  }
  .rec-carousel-wrapper{
    padding-bottom:2vh;
    position:relative;
  }
`

const ProductListContainer=styled.div`
  background-color:${({theme})=>theme.productsContainerBackground};
  width:80%;
  margin:40px 0px;
  padding:20px;
  margin-left:10%;
  
  .category{
    
    h2{
      color:${({theme})=>theme.mainThemeColor};
      font-size:20px;
      padding:20px 0px 0px 40px;
      width:100%;
      position:relative;
      &:before{
        content:'Tüm ilanlar →';
        position:absolute;
        right:0;
        cursor:pointer;
        font-size:14px;
        font-weight:normal;
        &:before:hover{
          #341f21;
        }
      }
    }
    &:not(:last-child){
      border-bottom:1px solid ${({theme})=>theme.color};
    }
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    position:relative;
    h1{
      width:100%;
      margin-left:30px;
    }
  }
  @media only screen and (max-width:1140px){
    width:95%;
    margin-left:2.5%;
  }
`

export {SliderSwitch,CarouselContainer,SlideProductContainer,ProductListContainer}