import styled from "styled-components";
import { rgba } from 'polished';

const ProductItem=styled.div`
  @media only screen and (max-width:1140px){
    & + div{
      width:100%; 
    }
    width:100%;
  }
  
  &:hover{
    box-shadow:0px 0px 41px #000;
  }
  margin:10px;
  width:15%;

  padding:10px;
  border-radius:10px;
  min-height:300px;
  max-height:300px;
  cursor:pointer;
  transition:500ms all;
  h3{
    font-size:13px;
    font-weight:normal;
    max-height:60px;
    padding:5px 10px 10px 5px;
  }
  img{
    padding:5px;
    width:100%;
    height:170px;
    user-select:none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .action{
    position:relative;
    .price{
      display:flex;
      opacity:100%;
      flex-direction:column;
      justify-content:center;
      padding:5px 5px;
      transition:600ms all;
      h4{
        font-size:15px;
        color:${({theme})=>theme.mainThemeColor};
      }
      h5{
        font-size:12px;
        text-decoration:line-through;
      }
    }
    
    .basket{
      transition:800ms all;
      top:10px;
      button{
        border:0;
        outline:0;
        width:200px;
        padding:10px 0px;
        background-color:${({theme})=>theme.mainThemeBackground};
        transition:600ms all;
        color:${({theme})=>theme.mainThemeColor};
        border-radius:10px;
        &:hover{
          background-color:${({theme})=>theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    

  }
  
`

const ProductList = styled.div`
  width:100%;
  @media only screen and (max-width:1140px){
    width:100%;
  }
  margin-top:40px;
  margin-bottom:40px;
  background-color:${({theme})=>theme.dropDownBackground};
  border-radius:20px;
  display:flex;
  
  
  &.product-list{
    flex-direction:column;
    .product-item{
      transition:500ms all;
      box-shadow:0px 2px 4444px #cccccc;
      background-color:${({theme})=>theme.mainThemeBackground};

      &:hover{
        box-shadow:0px 0px 41px ${({theme})=>theme.mainThemeColor};
        cursor:pointer;

      }
    
      width:100%;
      display:flex;
      justify-content:space-around;
      align-items:center;
      margin-bottom:20px;
      .price{
        font-size:17px;
      }
      .name{
        font-size:14px;
        min-width:40%;
      }
      .basket{
        
        transition:800ms all;
        opacity:1;
        button{
          border:0;
          outline:0;
          width:200px;
          padding:10px 0px;
          background-color:${({theme})=>theme.mainThemeBackground};
          transition:600ms all;
          color:${({theme})=>theme.mainThemeColor};
          border-radius:10px;
          &:hover{
            background-color:${({theme})=>theme.mainThemeOpacityBackgroundHover};
          }
        }
        
      }
      position:relative;
      .product-count{
        position:absolute;
        right:10px;
        
        transition:600ms all;
        font-size:15px;
        z-index:3;
        background-color:${({theme})=>rgba(theme.mainThemeOpacityBackgroundHover,.81)};
        color:#fff;
        border-radius:50%;
        padding:5px;
      }
      .image{
        min-width:10%;
        height:100px;
        transition:500ms all;
        background-size:cover;
        background-position:center;
      }
      &:hover{
        .product-count{
          opacity:1;
        }
        .image{
        }
      }
    }
    @media only screen and (max-width:1140px){
      display:flex;
      flex-wrap:wrap;
      justify-content:space-between;
      align-items:center;
      .product-item{
        background-color:${({theme})=>theme.mainThemeBackground};
        flex-direction:column;
        &:hover{
          .basket,.product-count{
            opacity:100%;
          }
        }
        position:relative;
        .product-count{
          position:absolute;
          opacity:0;
          top:10px;
          right:10px;
          transition:600ms all;
          font-size:22px;
          z-index:1;
          background-color:${({theme})=>theme.mainThemeBackground};
          border-radius:50%;
          padding:5px;
        }
        .basket{
          width:100%;
          display:flex;
          justify-content:center;
          align-items:center;
          transition:800ms all;
          top:10px;
          opacity:100%;
          button{
            border:0;
            outline:0;
            width:200px;
            padding:10px 0px;
            background-color:${({theme})=>theme.mainThemeBackground};
            transition:600ms all;
            color:${({theme})=>theme.mainThemeColor};
            border-radius:10px;
            &:hover{
              background-color:${({theme})=>theme.mainThemeOpacityBackgroundHover};
            }
          }
          
        }
        min-width:300px;
        margin-top:40px;
        padding:2%;
        margin:.1%;
        width:16%;
        min-height:400px;
        border-radius:20px;
        cursor:pointer;
        max-height:400px;
        transition:500ms all;
        .price{
          font-size:22px;
          font-weight:bold;
        }
        .name{
          font-size:14px;
          margin-top:30px;
        }
        .image{
          width:100%;
          transition:500ms all;
          height:200px;
          background-size:cover;
          background-position:center;
        }
        &:hover{
          .image{
            transform:scale(.9);
          }
          box-shadow:0px 0px 53px #000;
        }
      }
      
      @media only screen and (max-width:1140px){
        .product-item{
          width:100%;
          
          min-height:60vh;
          max-height:60vh;
          .image{
            height:40vh;
          }
        }
      }
    }
  }
  &.product-grid{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    .product-item{
      box-shadow:0px 2px 4px #cccccc;
      &:hover{
        box-shadow:0px 5px 10px #cccccc;
      }
      h3{
        font-size:15px;
        font-weight:normal;
        color:#000;
        padding:5px 15px 10px 15px;
      }
      .image-box{
        display:flex;justify-content:center;align-items:center;
        width:100%;
        height:180px;
        margin-bottom:20px;
        overflow:hidden;
        object-fit: cover!important;
        img{
          width:100%; height:100%;
          object-fit: cover!important;
          user-select:none;
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
      
      min-width:300px;
      margin-top:40px;
      padding:2%;
      margin:.1%;
      width:16%;
      min-height:500px;
      border-radius:20px;
      cursor:pointer;
      max-height:550px;
      transition:500ms all;
      color:#000;
      .price{
        font-size:22px;
        font-weight:bold;
      }
      .name{
        font-size:14px;
        margin-top:30px;
      }
      .basket{
        transition:800ms all;
        width:30%;
        display:flex;justify-content:center;align-items:center;
        button{
          border:0;
          color:#000;
          outline:0;
          padding:5px 10px;
          background-color:${({theme})=>theme.mainThemeBackground};
          transition:600ms all;
          color:${({theme})=>theme.mainThemeColor};
          border-radius:1000px;
          &:hover{
            color:${({theme})=>theme.mainThemeBackground};
            background-color:${({theme})=>theme.mainThemeColor};
          }
        }
      }
      .image{
        width:100%;
        transition:500ms all;
        height:200px;
        background-size:cover;
        background-position:center;
      }
      &:hover{
        .image{
          transform:scale(.9);
        }
        box-shadow:0px 0px 53px #000;
      }
    }
    
    @media only screen and (max-width:1140px){
        align-items:center;
        justify-content:center;
      .product-item{
        min-height:60vh;
        max-height:60vh;
        .image{
          height:40vh;
        }
      }
    }
  }
`
const FilterContainer=styled.div`
  width:100%;
  background-color:#fff;
  padding:20px;
  border-radius:20px;
  transition:800ms all;
  h1{
    font-size:20px;
    margin-bottom:20px;
    border-bottom:1px solid ${({theme})=>theme.mainThemeOpacityBackgroundHover};
  }
  .filter-item{

    .brand-list{
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      margin-block-start: 0px!important;
      margin-block-end: 0px!important;
      padding-inline-start: 0px!important;
      list-style-type:none!important;
      overflow:scroll;
      margin-left:0px;
      ul,li{
        margin-left:0px;
        display:flex;
        color:#222;
        *{
          font-family: 'Overpass', sans-serif!important;
        }

      }
      li{
        *{
          padding:8px 20px;
          inline-size: max-content;
        }

        margin:5px;
        text-align:center;
        *{
          user-select:none;
        }

        display:flex;justify-content:center;align-items:center;
      }
      li:hover{
        cursor:pointer;
        border:1px solid #000;
      }
      .active{
        font-weight:bold;
      }
    }
    display:flex;
    flex-direction:column;
    margin-bottom:20px;
    margin-top:20px;
    h3{
      font-size:16px;
      width:100%;
      font-weight:bold;
      padding:5px;
      border-radius:10px;
      
      &:hover{
        background-color:${({theme})=>theme.mainThemeBackground};
      }
    }
    ul ul:last-child{
      padding-left:13%;
      
    }
    .active > a{
      text-align:center;
      color:${({theme})=>theme.mainThemeColor};
    }
    ul{
      &::-webkit-scrollbar {
        display: none;
      }
      max-width:100%;
      overflow:scroll;
      padding-left:80px;
      display:flex;
      flex-direction:row;

      li{
        border:1px solid #616161;
        border-radius:20px;

      }
      li.items{
        a{
          inline-size: max-content;
        }
        border:1px solid ${({theme})=>theme.color};

        background-color:#fefeff;
        text-align:center;
        border-radius:30px;
        border:1px solid #616161;
        padding:8px 20px ;
        margin:10px 6px;
        font-size:10px;
        display:flex;
        justify-content:center;
        align-items:center;
        font-family: 'Overpass', sans-serif!important;

        transition:300ms all;
        &:hover{
          background-color:${({theme})=>theme.mainThemeBackground};
          a{
            color:#fff!important;
          }
        }
        b{
          cursor:pointer;
        }
      }
      li.active{
        border:none;
        color:#000;

        background-color:${({theme})=>theme.mainThemeBackground};
      }
      .active-brand{
        background-color:#fafafa!important;
        
        border:2px solid ${({theme})=>theme.altHeader}!important;
      }
      li a{
        text-decoration:none;
        font-family: 'Overpass', sans-serif!important;
        color:#666;
        transition:300ms all;
        font-size:12px;
        font-weight:bold;
        
        cursor:pointer;
      }
      padding-inline-start:0px;
      margin-inline-start:0px;
    }
    &>ul{
    }
  }
  .head{
    position:relative;
    svg{
      position:absolute;
      top:25%;
      right:10px;
      transform:translateY(-25%);
    }
    padding-bottom:20px;
    border-bottom:1px solid ${({theme})=>theme.mainThemeOpacityBackgroundHover};
    input{
      border:0;
      outline:0;
      width:100%;
      padding:10px;
      border-radius:10px;
      &:hover,&:focus{
      background-color:${({theme})=>theme.mainThemeBackground};
      }
    }
  }
  @media only screen and (max-width:1140px){
    position:relative;
    width:100vw;
    overflow:scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .filter-item li{
      min-width:40%!important;
    } 
  }

`

const ProductListTitle = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  flex-wrap: wrap;

  .items {
    display: flex;
    justify-content: product;
    align-items: center;
    .sortDrop {
      position: absolute;
      top: 100%;
      width: 100%;
      background-color: ${({ theme }) => theme.productsContainerBackground};
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      a:last-child:hover {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      a {
        text-decoration: none;
        color: ${({ theme }) => rgba(theme.mainThemeOpacityBackgroundHover, 1)};
        display: block;
        padding: 5px;
        width: 100%;
        &:hover {
          background-color: ${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .productSort {
      button {
        background-color: rgba(0, 0, 0, 0.05);
        border: 0;
        outline: 0;
        padding: 5px 20px;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    padding: 20px;
    .productSort {
      button {
      }
    }
  }

  .listNav {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    h1 {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px;
      font-weight: bold;
      transition: 300ms all;
      color: ${({ theme }) => theme.mainThemeColor};
      &:hover {
        color: ${({ theme }) => theme.mainThemeOpacityBackgroundHover};
      }
    }
    h2 {
      font-size: 15px;
    }
  }
`;

const DetailContainer = styled.div`
  .detail-image {
    width: 100%;
  }
  .carousel-an-con {
    .carousel-animate {
      background: red;
      position: absolute;
      font-size: 44;
      color: #fff;
      transition: 600ms all;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 950%;
      background: #000;
    }
    &:hover {
      .carousel-animate {
        opacity: 0.1352;
        width: 100%;
        height: 100%;
      }
    }
  }
  button:hover .carousel-animate{
    display:none;
  }

  .detail-header {
    width: 98%;
    padding: 1% 2%;
    margin-bottom: 15px;
    background-color: ${({ theme }) => theme.productsContainerBackground};
    display: flex;
    justify-content: product;
    flex-wrap: wrap;
    .title {
      width: 70%;
      text-align: right;
      h1 {
        font-size: 24px;
      }
    }
    .detail-nav {
      color: ${({ theme }) => theme.mainThemeBackground};

      a {
        text-decoration: none;
        font-weight: bold;
        color: #000;
        transition: 300ms all;
        &:hover {
          color: ${({ theme }) => theme.mainThemeBackground};
        }
      }
      span {
        color: ${({ theme }) => theme.mainThemeBackground};
      }
      width: 25%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .product-information {
    width: 90%;
    padding: 1% 2%;
    color: #000;
    p {
      font-family: "Overpass", sans-serif !important;
    }
    margin-top: 15px;
    background-color: ${({ theme }) => theme.productsContainerBackground};
  }
  padding: 10vh 0vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product {
    .product-store {
      position: relative;
      padding: 0.5%;
    }
    .product-store.notstock {
      & > div {
        opacity: 22%;
      }

      &::after {
        content: "TÜKENDİ";
        position: absolute;
        z-index: 100;
        bottom: calc(50%);
        right: 0;
        background: ${({ theme }) => theme.mainThemeBackground};
        width: 100%;
        font-family: "Overpass", sans-serif !important;
        padding: 5px 0px;
        opacity: 90%;
        text-align: center;
        box-shadow: 0px 0px 5px 0px red;
        font-weight: bold;
        font-size: 48px;
        transform: rotate(-40deg);
      }
    }
    .product-title {
      text-align: left;
      color: #000;
      font-size: 24px;
      font-weight: bold;
    }
    .product-colors {
      text-align: left;
      color: #000;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 40px 0px;

      font-weight: bold;
      a {
        color: #000;
        display: inline-block;
        background: #ffef;
        margin: 10px;
        padding: 10px 25px;
        max-width: 50%;
        text-decoration: none;
        border-radius: 15px;
      }
      a.active {
        background: ${({ theme }) => theme.mainThemeBackground};
        color: #fff;
        font-weight: bold;
      }
      a.no-stock {
        background: gray;
        color: #fff;
        opacity: 50%;
      }
    }
    .price-list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .product-price {
      min-width: 30%;
      display: inline-block;
      background-color: ${({ theme }) => theme.mainThemeBackground};
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &.adet {
        background-color: #933;
        min-width: 30%;
        font-size: 18px;
        color: #fefe;
      }
      margin-top: 10px;
      padding: 3px 12px;
      border-radius: 5px;
      text-shadow: 1px 1px 1px #000;
      font-size: 35px;
      font-weight: bold;
      font-family: "Overpass", sans-serif !important;
      box-shadow: 6px 6px 0px 0px #000;
      color: ${({ theme }) => theme.color};
      text-align: left;
    }
    .product-stock {
      float: right;
      padding: 3px 12px;
      border-radius: 5px;
      text-shadow: 3px 3px 3px #000;
      font-size: 20px;
      font-weight: bold;
      font-family: "Overpass", sans-serif !important;
      text-align: center;
      color: #ffefef;
      background: #333;
      display: inline-block;
    }
    .counter {
      margin-top: 100px;
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        min-width: 25%;
        background-color: ${({ theme }) => theme.mainThemeBackground};
      }
      button:not(:last-child) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      button:not(:first-child) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      input {
        width: 50%;
        border: 0;
        outline: 0;
        text-align: center;
        font-weight: bold;
        font-family: "Overpass", sans-serif !important;
        color: #000;
        font-size: 28px;
        &:hover,
        &:focus {
          // background-color:${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .add-basket {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-right: 20px;
      }
      button {
        background-color: ${({ theme }) => theme.mainThemeBackground};
        width: 100%;
        &:hover {
          background-color: ${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .list-price {
      box-shadow: none;
      background: transparent;
      color: #000;
      font-size: 34px;
      &:before {
        content: "";
        width: 170px;
        transform: rotate(-9deg);
        background: ${({ theme }) => theme.altHeader};
        height: 3px;
        z-index: 1000;
        position: absolute;
      }
    }
    width: 90%;
    background-color: ${({ theme }) => theme.productsContainerBackground};
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .product-image {
      width: 50%;
    }
    .product-store {
      width: 50%;
      height: 100%;
    }
    @media only screen and (max-width: 1140px) {
      flex-wrap: wrap;
      .product-image {
        min-width: 100vw;
        min-height: 100vw;
        img {
          min-width: 100vw;
          min-height: 100vw;
        }
      }
      .product-store {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .detail-image {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .detail-header {
      .detail-nav {
        width: 100%;
      }
    }
  }
`;


const InvoiceContainer=styled.div`
  .head{
    display:flex;
    justify-content:space-between;
    .leftHead{
      width:20%;
      div{
        width:100%;
        display:flex;
        justify-content:space-between;
        margin-bottom:10px;
        span{
          min-width:40%;
        }
      }
    }
  }
  .total{
    display:flex;
    justify-content:space-between;
    margin-top:20px;
    div{
      display:flex;
      align-items:center;
      justify-content:center;
      div{
        border:none;
        padding:5px;
      }
      border:1px solid #000;
    }
    span{
      padding:10px;
    }

  }

`

export {ProductItem,DetailContainer,ProductList,FilterContainer,ProductListTitle,InvoiceContainer}