import { createSlice } from "@reduxjs/toolkit";

export const cart = createSlice({
  name:'cart',
  initialState:{
    basket:localStorage.getItem('cart')?JSON.parse(localStorage.getItem('cart')):null,
  },
  reducers:{

    setBasket: (state,action) => {
      localStorage.setItem('cart',JSON.stringify(action.payload))
      state.basket=action.payload
    }
    
  }
})

export const {setBasket,itemIncrement} = cart.actions
export default cart.reducer