import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import {FooterContainer} from '../../styles/Header'
import logo from '../../mainlogo.png'
export default function Footer() {
  return (
    <FooterContainer>
      <div className="about">
        <div>
          <h1>Dehasoft B2B Hizmeti</h1>
          <p> </p>
          <p> </p>
        </div>
        <div>
          <img onContextMenu={() => false} src={logo} />
          <div style={{width:'100%',background:'#3333',display:'flex',justifyContent:'space-around',alignItems:'center'}}>
             
          </div>
        </div>
      </div>

      <div className="footerNavs">
        {/* <div>
          
          <h1>Dehasoft</h1>
          
          <ul>
            <li><Link to="/">Hakkımızda</Link></li>
            <li><Link to="/">Bize Ulaşın</Link></li>
            <li><Link to="/">E-Ticaret API</Link></li>
            <li><Link to="/">Kurumsal</Link></li>
            <li><Link to="/">Müşteri Hizmetleri</Link></li>
          </ul>

        </div>

        <div>
          
          <h1>İşlem Rehberi</h1>
          
          <ul>
            <li><Link to="/">Nasıl Ürün Alırım?</Link></li>
            <li><Link to="/">Ödeme Seçenekleri</Link></li>
            <li><Link to="/">Alış Sonrası İşlemler</Link></li>
            <li><Link to="/">İade Süreçleri</Link></li>
            <li><Link to="/">Nasıl İade Yapılır?</Link></li>
          </ul>

        </div>

        <div>
          
          <h1>Popüler Sayfalar</h1>
          
          <ul>
            <li><Link to="/">iPhone</Link></li>
            <li><Link to="/">Samsung</Link></li>
            <li><Link to="/">Xiaomi</Link></li>
            <li><Link to="/">Huahwei</Link></li>
            <li><Link to="/">Kedi Maması</Link></li>
          </ul>

        </div>

        <div>
          <h1 className="spaces"></h1>
          <ul>
            <li><Link to="/">Akıllı Saat</Link></li>
            <li><Link to="/">Akvaryum</Link></li>
            <li><Link to="/">Kitaplık</Link></li>
            <li><Link to="/">Antifiriz</Link></li>
            <li><Link to="/">Buzdolabı</Link></li>
          </ul>

        </div> */}
      </div>

      <div className="follow">
        <div>
          <h1>Bizi Takip Edin</h1>
          <Link to="/">
            <FontAwesomeIcon icon={faLinkedin} />
          </Link>
          <Link to="/">
            <FontAwesomeIcon icon={faInstagram} />
          </Link>
          <Link to="/">
            <FontAwesomeIcon icon={faGlobe} />
          </Link>
        </div>
        <div>
          {/* <h1>Mobil Uygulamalar</h1>
          <Link to="/"><img onContextMenu={()=>false} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"  alt="App-Store-Link"/></Link>
          <Link to="/"><img onContextMenu={()=>false} src="https://www.enersee.io/wp-content/uploads/2021/01/global-playstore.png"  alt="Play-Store-Link"/></Link> */}
        </div>
      </div>

      <div className="copyright">
        <div>&copy; Copyright 2023 - Dehasoft</div>

        <div></div>
        <div>
          <Link to="/">Çerez Politikası</Link>
        </div>
        {/*  <div><Link to="/">Kullanıcı Sözleşmesi</Link></div> */}
      </div>
    </FooterContainer>
  );
}
